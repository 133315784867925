export default {
  communication: {
    name: "Communication & Outreach",
    info: "Developing Awareness of and Inspiration for Future Quantum Technologies",
    colorClass: "communication",
    image: "",
  },
  diversity: {
    name: "Diversity, Equity & Inclusion",
    info: "Fostering wide access the quantum technology landscape, regardless of background",
    colorClass: "diversity",
    image: "",
  },
  standardization: {
    name: "Standardization",
    info: "The competence framework as a backbone to learning outcomes and industry needs",
    colorClass: "standardization",
    image: "",
  },
  representation: {
    name: "Organisational Representation",
    info: "The need of the future quantum workforce represented",
    colorClass: "representation",
    image: "",
  },
  innovation: {
    name: "Teaching Innovation",
    info: "Best practices in education informed by the experts of the entire QTEdu community",
    colorClass: "innovation",
    image: "",
  },
  access: {
    name: "Widening Access",
    info: "Thigh quality, didactically validated modules made available for free for all",
    colorClass: "access",
    image: "",
  },
};
