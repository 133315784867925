<template>
  <div class="organisationAccords w-full">
    <SuccessMessage title="Congratulations!"></SuccessMessage>
    <LTabBody>
      <template #body>
        <div class="flex justify-between">
          <h3 class="heading3">Accords</h3>
        </div>
        <div class="accords">
          <div class="flex gap-8">
            <div class="flex flex-wrap gap-8 mb-8">
              <Accord
                v-for="document in filteredDocuments"
                :data-type="document.dataType"
                :id="document.repoId"
                :key="document.id"
                class="w-full"
                @click="
                  $router.push({
                    name: 'accordDisplay',
                    params: { organisationId, assetId: document.repoId },
                  })
                "
              />
            </div>
          </div>
        </div>
      </template>
      <template #buttons>
        <button
          v-if="canCreateAccords"
          class="button main h-fit"
          @click="
            $router.push({
              name: 'createaccords',
              params: { organisationId: organisationId },
            })
          "
        >
          Create Accord
        </button>
      </template>
    </LTabBody>
  </div>
</template>

<script>
import LTabBody from "@/components/layout/LTabBody.vue";
import Accord from "@/components/accords/Accord.vue";
import { useDocuments } from "@/composables/useDocuments";
import SuccessMessage from "@/components/forms/SuccessMessage.vue";
import { computed, watch } from "vue";
import { useDocument } from "@/composables/useDocument";

export default {
  name: "OrganisationAccords",
  components: {
    SuccessMessage,
    LTabBody,
    Accord,
  },
  props: {
    organisationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { organisation, loadOrganisation } = useDocument(
      "Organisation",
      props.organisationId
    );
    const { filter, documents, filteredDocuments, loadDocuments } =
      useDocuments("Accord", {
        refresh: true,
      });
    const canCreateAccords = computed(
      () => organisation.value._permissions > 1
    );

    watch(
      organisation,
      (value) => {
        if (value.repoId) filter.value.parentId = value.repoId;
      },
      { immediate: true }
    );

    loadOrganisation();
    loadDocuments(true);

    return {
      canCreateAccords,
      documents,
      organisation,
      filteredDocuments,
    };
  },
};
</script>

<style scoped></style>
